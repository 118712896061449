<template>

		<b-card
			overlay
			:img-src="backGround"
			img-alt="Card Image"
			:title="info.key"
			img-height="150rem"			
		>
			<b-card-text>
				{{ info.info }}
			</b-card-text>
		</b-card>	


	
</template>

<script>
import { BCardText, BCard, } from "bootstrap-vue";

export default {
	name: "HeaderInfoTrad",
	components: {
		BCard,
		BCardText,
	},
	data() {
		return {
			backGround : require('@/assets/images/banner/parallax-4.jpg')
		};
	},
	props: ['info'],

	methods: {},
};
</script>
