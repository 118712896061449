import axios from "axios";
import VueCookie from "vue-cookies";
import router from "@/router";
import ability from "@/libs/acl/ability";
import { initialAbility } from "@/libs/acl/config";


const url = "https://gateway.aba-live.fr/api/v1/";
//const url = "https://aba-api-dev.alt.bzh/api/v1/";
//const url = "http://localhost:4001/api/v1/";
const api = axios.create({
	baseURL: url,
	timeout: 30000,
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
		"x-no-cache": 1,
	},
});

api.interceptors.request.use(
	(request) => {
		const accessToken = VueCookie.get("accessToken");
		if (accessToken) {
			request.headers.Authorization = `Bearer ${accessToken}`;
		}
		return request;
	},
	(error) => {
		if (error.resquest) {
			return () => {
				this.$sweetError({
					title: "Impossible de joindre le serveur !",
					text:
						"Connexion internet interrompue : veuillez vérifier votre connexion et réssayer",
				});
			};
		}
	}
);

api.interceptors.response.use(
	(response) => {
		return response;
	},
	async function(error) {
		if (error.response.status === 401) {
			VueCookie.remove("accessToken");
			localStorage.removeItem("userData");
			localStorage.removeItem("accessToken");
			ability.update(initialAbility);
			router.push({ name: "authentification" });

			//originalRequest._retry = true;
			//const access_token = await refreshAccessToken();
			//axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
			//return axiosApiInstance(originalRequest);
		}
		return Promise.reject(error);
	}
);

export { api, url };
