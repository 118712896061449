<template>
  <b-row
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ Header.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top text-secondary"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item >
              <span class="text-secondary">{{ Header.config }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item :to='Header.listLink'>
              <span class="text-secondary">{{ Header.list }}</span>
              </b-breadcrumb-item>
               <b-breadcrumb-item :to='Header.detailLink'>
              <span class="text-secondary">{{ Header.detail }}</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol
  },
  props: [ 'Header' ]
}
</script>
