<template>
  <div ref="formContainer">
    <AppHeaderTitle :Header='Header'/>
    <AppHeaderInfoTrad :info="info" />
    <div v-for="items in trans" :key="items.key">
      <b-col md="8">
        <b-card no body>
            <div class="d-flex align-items-center mb-1">
              <feather-icon
                size="18"
                class="mr-1"
                icon="TagIcon"
              />
              <b-badge variant="primary">
                {{ items.key }}
              </b-badge>
            </div>
          <b-table
          :items="listTrans.values"
          :fields="fields"
          :small="small"
          >
            <!-- insert flag in cell languages -->
            <template #cell(lang)="item">
              <b-img
                :src="getFlag(item.value)"
                height="14px"
                width="22px"
                alt=""
              />
              <span class="ml-50 text-body">{{ item.value }}</span>
            </template>
            <!-- input of edit value  -->
            <template #cell(value)="item">
              <b-row align-v=center>
                <b-col md="1">
                  <feather-icon
                    v-if="item.item.t "
                    icon="CheckIcon"
                    class="mr-50"
                    stroke="green"
                    v-b-tooltip.hover.bottom="'Traduction faite'"
                  />
                  <feather-icon
                    v-else
                    icon="XCircleIcon"
                    class="mr-50"
                    stroke="red"
                    v-b-tooltip.hover.bottom="'Traduction non faite'"
                  />
                </b-col>
                <b-col md="11">
                  <b-input-group>
                    <b-form-input 
                    size="sm"
                    v-model="item.value" 
                    />
                    <b-input-group-append>
                      <b-button 
                      @click="editLang(items.key, item.item.t, item)"
                      variant="success"
                      size="sm">
                        Editer
                      </b-button>
                    </b-input-group-append>
                  </b-input-group> 
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </div>
  </div>
</template>
<script>
import { BCard, BFormInput, BButton,VBTooltip, BTable, BInputGroupAppend,BImg, BInputGroup,BBadge,BRow,BCol  } from 'bootstrap-vue';
import AppHeaderTitle from '@/app/views/components/AppHeaderTitle.vue';
import Ripple from 'vue-ripple-directive'
import AppHeaderInfoTrad from "@/app/views/components/AppHeaderInfoTrad.vue";
import { Translations } from '@/mixins/i18n.js'

export default {
  components: {
    AppHeaderInfoTrad,
    BCard,
    AppHeaderTitle,
    BFormInput,
    BButton,
    BTable,
    BInputGroupAppend, BInputGroup,
    BBadge,
    BRow,BCol,
    BImg
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  name: 'i18nDetails',
  mixins: [Translations],
  data() {
    return {
      small: true,
      Trads: [],
      listTrans: [],
      trans: [],
      i18nKey:"",
      Header: {
        config: "configuration",
				listLink: "/configuration/i18n/list",
				list: "traduction",
				detailLink: "",
				detail: "traduction-detail",
			},
      info: [],
      fields : [
        { key: 'lang', label: 'LANGUAGE' },
        { key: 'value', label: 'TRADUCTION' },
      ], 
      langArray: [{
        lang: "fr_FR",
        img : require('@/assets/images/flags/fr.png')
      },
      {
        lang: "en_EN",
        img : require('@/assets/images/flags/en.png')
      },
      {
        lang: "es_ES",
        img : require('@/assets/images/flags/es.png')
      },
      ],

    }
  },
  async mounted() {
    await this.getKey();
    await this.loadTransDetail();
    await this.loadTrans();
  },

  methods: {
    // function to get key in url 
     async getKey() {
      this.i18nKey = await this.$route.params.id
    },
    // API to load config detail by key
    async loadTransDetail() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
			});
      try {
        const datas = await this.getItemByKey(this.i18nKey);
        if (datas.status === 1) {
          this.trans = datas.datas,
          console.log('test trans', this.trans),
          [this.listTrans] = this.trans,
          console.log('test datas', this.listTrans)
        } else {
          loader.hide();
          this.$sweetError("AF-43");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-43");
      }
    },
    async editLang(key, t, data) {
      console.log("test t", t, data)
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
        });
      try {
         const response = await this.editItem(key, data.item.lang, data.value);
        if (response.status === 1) {
          this.$sweetNotif("Modification réussie");
          loader.hide();
         } else {
           loader.hide();
           this.$sweetError("AF-43");
         }
      } catch {
        loader.hide();
        this.$sweetError("AF-43")
      }
    },
    async loadTrans() {
      let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: true,
        });
      try {
        this.Trads = await this.getAll(),
        [this.info] = this.Trads.datas.filter( result => result.key == this.i18nKey )
        loader.hide();
      } catch {
        loader.hide();
         this.$sweetError("AF-43")
      }
    }, 
    getFlag(lang) {
      const flag = this.langArray.find(element => element.lang == lang);
      return flag.img  
    }
  },
}
</script>
