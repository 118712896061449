import {api} from '@/services/axios'

export const Translations  = {
  methods: {
    // API get all Section i18n
    async getAll() {
      try {
        const response = await api.get('getter/infos/i18n_Section')
        // console.log('test API', response)
        return {
          status: 1,
          datas: response.data.datas
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
    // Get item by key 
    async getItemByKey(key) {
      try {
        const response = await api.get(`getter/infos/i18n_Section/${key}`)
        return {
          status: 1,
          datas: response.data.datas
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
    async editItem(key, lang, value) {
      try {
        const values_formatted = {
          key,
          values: [
            {
              lang,
              value
            }
          ]
        } 
        const response = await api.put('setter/i18n_item', values_formatted)
        //console.log("test response",response)
        return {
          status: 1,
          datas: response.data.datas
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
  } 
}

